import styles from './Logs.module.scss'
import api from '../../api'
import React, { useEffect, useState } from 'react'
import TableColTitle from 'components/TableColTitle/TableColTitle'
import dayjs from 'dayjs'
import style from '../Makes/Makes.module.scss'
import styleExp from '../Exceptions/Exceptions.module.scss'
import Select from 'components/Select/Select'
import Input from 'components/Input/Input'
import searchIcon from '../../assets/img/searchColored.svg'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
import Pagination from '../../components/Pagination/Pagination'
import CheckBox from '../../components/CheckBox/CheckBox'

type OrderBy = { value: keyof WorkerTask; reverse: boolean }

const CarLogs = () => {
  // @ts-ignore
  const [hideDisabledStatus, setHideDisabledStatus] = useState<boolean>(true)
  const [showSuccessfulResult, setShowSuccessfulResult] = useState<boolean>(false)
  const [page, setPage] = useState(0)
  const { data: dates, isFetching: datesIsLoading } = api.useGetLogDatesQuery()
  const { data: autoDealers, isFetching: dataIsLoading } = api.useGetAutoDealersQuery()
  const [date, setDate] = useState<number | undefined>(undefined)
  const [dealer, setDealer] = useState<any | undefined>(undefined)
  const [filterName, setFilterName] = useState<string | undefined>()
  const [carVin, setCarVin] = useState<string | undefined>()
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: true })
  const {
    data: logs,
    error,
    isLoading,
  } = api.useGetCarLogsQuery({
    page,
    date: date,
    car_id: filterName,
    car_vin: carVin,
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
    hide_status: hideDisabledStatus,
    show_successful_result: showSuccessfulResult,
    dealer: dealer,
  })

  const getDealerSelectValue = () => {
    if (dealer !== undefined) {
      const m = autoDealers.find((m) => m.id === dealer)
      if (m) return { id: m.id, label: m.display_name }
      else return undefined
    } else return undefined
  }

  const [selectedException, setSelectedException] = useState<any | undefined>()
  const onExceptionClick = (e: any) => {
    setSelectedException(e)
  }
  const renderObject = (obj, prefix = '') => {
    return Object.keys(obj).map((key) => {
      const value = obj[key]
      const newKey = prefix ? `${key}` : key

      if (typeof value === 'object' && value !== null) {
        return (
          <div key={newKey}>
            <h3>Failed step: {parseInt(newKey) + 1}</h3>
            <div style={{ paddingLeft: '20px' }}>{renderObject(value, newKey)}</div>
          </div>
        )
      } else {
        return (
          <div key={newKey}>
            <strong>{newKey}:</strong> {value ? value.toString() : 'null'}
          </div>
        )
      }
    })
  }
  const onChangeOrderBy = (value) => {
    // @ts-ignore
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  useEffect(() => {
    if (dates !== undefined && dates.length > 0) setDate(dates[0])
  }, [dates])

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error fetching logs</div>
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h1 className={styles.title}>Feed processing logs</h1>
      </div>

      {selectedException && (
        <Modal onCloseModal={() => setSelectedException(undefined)}>
          <div className={styles.logModal}>
            <h2 className={styles.logModalTitle}>Record details</h2>
            <div>
              {/*<h3>Steps:</h3>*/}
              {renderObject(JSON.parse(selectedException.message))}
            </div>
            <div className={styles.vehicle}>
              <h3>Vehicle info:</h3>
              {renderObject(selectedException.vehicle)}
            </div>
            <div className={styles.additional_info}>
              <h3>Additional Info:</h3>
              {renderObject(selectedException.additional_info)}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => setSelectedException(false)} type={'button'} className={styles.closeBtn}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <div className={style.filters}>
        <h4>Filters</h4>
        <Input size={'small'} label={'Car ID'} value={filterName} onChange={setFilterName} />
        <Input size={'small'} label={'Car VIN'} value={carVin} onChange={setCarVin} />
        <label style={{ marginLeft: '15px' }}>Date:</label>
        <Select
          placeholder={'All'}
          value={date ? { id: date, label: dayjs(date * 1000).format('lll') } : undefined}
          options={dates?.map((d) => ({ id: d, label: dayjs(d * 1000).format('lll') }))}
          onChange={(v) => setDate(v ? Number(v.id) : undefined)}
          clearable
          btnClassName={styleExp.dateSelectBtn}
        />
        <label style={{ marginLeft: '15px' }}>Dealer:</label>
        <Select
          placeholder={'All'}
          searchable
          size={'small'}
          value={getDealerSelectValue()}
          options={autoDealers?.map((d) => ({ id: d.id, label: d.display_name }))}
          onChange={(v) => setDealer(v ? Number(v.id) : undefined)}
          clearable
          btnClassName={styleExp.dateSelectBtn}
        />
      </div>

      <div className={style.filters}>
        <h4>Toggles:</h4>
        <div className={styles.checkBox}>
          <CheckBox
            id={0}
            onClick={(value) => {
              setHideDisabledStatus(value)
            }}
            selected={hideDisabledStatus}
          />
        </div>
        <h4>Hide Disabled & Unknown Dealers</h4>

        <div className={styles.checkBox}>
          <CheckBox
            id={1}
            onClick={(value) => {
              setShowSuccessfulResult(value)
            }}
            selected={showSuccessfulResult}
          />
        </div>
        <h4>Show Successful Entries</h4>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                <TableColTitle label={'Car ID'} name={'vin'} />
              </th>
              <th>
                <TableColTitle label={'Make'} name={'make'} />
              </th>
              <th>
                <TableColTitle label={'Model'} name={'model'} />
              </th>
              <th>
                <TableColTitle label={'Price'} name={'price'} />
              </th>
              <th>
                <TableColTitle label={'Service'} name={'stage'} />
              </th>
              <th onClick={() => onChangeOrderBy('result')}>
                <TableColTitle label={'Result'} name={'result'} sorted={orderBy} />
              </th>
              <th>
                <TableColTitle label={'Timestamp'} name={'timestamp'} />
              </th>
            </tr>
          </thead>
          <tbody>
            {logs?.objects.map((log) => (
              <tr key={log._id}>
                <td>{log.car_id ? log.car_id : log.car_uuid}</td>
                <td>{log.vehicle?.make}</td>
                <td>{log.vehicle?.model}</td>
                <td>{Number(log.vehicle?.price).toLocaleString()}</td>
                <td>{log.service ? log.service : '-'}</td>
                <td>{log.result}</td>
                <td>{log.timestamp ? dayjs(log.timestamp).format('lll') : '-'}</td>
                {log.result === 'error' ? (
                  <td>
                    <div style={{ display: 'flex' }}>
                      <button
                        className={styles.fixBtn}
                        onClick={() => onExceptionClick(log)}
                        title={'Fix'}
                        style={{ marginRight: 10 }}>
                        <img src={searchIcon} alt={'Fix'} />
                      </button>
                    </div>
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {logs && (
        <Pagination
          pageCount={logs.pagination.count}
          curPage={page + 1}
          onChange={(p: number) => setPage(p - 1)}
          className={styles.pagination}
        />
      )}
    </div>
  )
}

export default CarLogs
